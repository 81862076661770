<template>
  <div>
    <div class="key-visual">
      <div class="mask">
        <!--<div class="contents">
          <div class="txt">
            Hello We Are<br />
            Full service<br />
            Digital Agency<br />
            e · motion global
          </div>
          <router-link to="/" class="key-button">See works</router-link>
        </div>-->
        <div class="btn-area">
          <button
            type="button"
            :class="{muted: isMuted}"
            @click="soundHandler"
            aria-label="음소거 버튼"
          >
            <lottie-muted v-if="!isMuted"></lottie-muted>
          </button>
          <div class="right">
            <a
              class="social"
              href="https://www.facebook.com/emotion.co.kr/"
              aria-label="facebook"
              target="_blank"
            ></a>
            <a href="https://www.behance.net/emotionglobal" aria-label="Behance" target="_blank">Behance</a> <!-- 버튼 추가 -->
            <router-link to="/request">프로젝트 문의</router-link>
          </div>
        </div>
        <div class="video-item">
          <video id="vid" autoplay playsinline muted loop>
            <source src="@/assets/images/emotion_mobile.mp4" type="video/mp4" />
            <!--            <source src="@/assets/images/about/video_mo.mp4" type="video/mp4" />-->
          </video>
        </div>
        <!-- <div
          class="bg"
          :style="{
            backgroundImage: `url(${require('@/assets/images/@temp/main_visual.jpg')})`
          }"
        ></div>-->
      </div>
    </div>
    <div class="contents">
      <div class="title-area mt0">
        <strong class="title">
          <span>
            이모션글로벌의<br />
            전문가들은 각기 다른 재능으로<br />
          </span>
          위대함을 만들어 갑니다.
        </strong>
        <router-link to="/about" class="more">View More</router-link>
      </div>
    </div>
    <div v-swiper:mySwiper="swiperOptions">
      <div class="swiper-wrapper swiper-area">
        <div class="swiper-slide swiper-item" v-for="(item, index) in swiperData" :key="index">
          <div class="about-item">
            <span class="thumb">
              <img :src="item.subImagePhysicalName" alt="이미지" />
            </span>
            <strong class="title">
              {{ item.title }}
            </strong>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="contents" id="test1">
      <div class="title-area type2">
        <strong class="sub-title"> Featured Works </strong>
        <router-link to="/works" class="more">View More</router-link>
      </div>
      <div class="work-list">
        <div v-for="(item, index) in WorksListData" :key="index" class="work-item">
          <router-link :to="`/works/${item.id}`">
            <span class="thumb">
              <img :src="imgUrl(item.moSmallImagePhysicalName)" :alt="item.projectClientName" />
            </span>
            <span class="title" :style="`color:${item.listFont}`">
              {{ item.projectServiceName }}
            </span>
          </router-link>
        </div>
      </div>

      <div class="title-area">
        <strong class="title">
          <span>이모션글로벌의 시선으로</span><br />
          세상을 이야기합니다.
        </strong>
        <router-link to="/magazine" class="more">View More</router-link>
      </div>
      <ul class="magazine-list">
        <li v-for="(item, index) in magazineListData" :key="index">
          <router-link :to="`/magazine/${item.id}/?list=${totalLength - index}`">
            <span class="num">
              {{ totalLength - index }}
            </span>
            <strong class="title">
              {{ item.magazineTitle }}
            </strong>

            <span class="date">
              {{ item.reportingDate | dateFormat }}
            </span>
            <span class="desc">
              {{ item.magazineDesc }}
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import {TweenMax, Power0} from "gsap";
  import {apiMagazineList, apiWorksList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  import LottieMuted from "@/components/lottie/muted.vue";

  export default {
    name: "home",
    components: {LottieMuted},
    data() {
      return {
        isMuted: true,
        scene: null,
        swiperData: [
          {
            title: "UX / CX Consulting",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/00_UX-CX.png")
          },
          {
            title: "UX Research & Strategy",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/01_Research-Strategy.png")
          },
          {
            title: "Experience Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/02_Experience.png")
          },
          {
            title: "UI / GUI Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/03_UI-GUI.png")
          },

          {
            title: "Brand Identity Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/04_Brand-Identity.png")
          },

          {
            title: "Interaction Design",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/05_Interaction-Design.png")
          },
          {
            title: "2D/3D Motiongraphic",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/06_Motiongraphic.png")
          },
          {
            title: "Creative idea",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/07_Creative-Idea.png")
          },
          {
            title: "Creative Development",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/08_Creative-Development.png")
          },
          {
            title: "Digital Transformation",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/09_Digtal-Transformation.png")
          },
          {
            title: "SNS Marketing",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/10_SNS-Marketing.png")
          },
          {
            title: "Total Management",
            link: "/",
            subImagePhysicalName: require("@/assets/images/about/11_Total-Management.png")
          }
        ],
        totalLength: 0,
        magazineListData: [],
        WorksListData: [],
        swiperOptions: {
          autoplay: true,
          loop: true,
          centeredSlides: true,
          slidesPerView: "auto"
        }
      };
    },
    activated() {
      this.init();
    },
    mounted() {
      this.init();
      const tween = TweenMax.to("#animate1", 1, {
        opacity: "1",
        //scale: 2.5,
        //rotation: 360,
        ease: Power0.easeInOut,
        y: 800
        //x: '1000',
      });
      this.scene = this.$scrollmagic
        .scene({
          triggerElement: "#test1",
          triggerHook: 1,
          duration: "800"
        })
        .setTween(tween);
      this.$scrollmagic.addScene(this.scene);
    },
    destroyed() {
      this.scene?.destroy(true);
    },
    mixins: [commonMethods],
    methods: {
      soundHandler() {
        this.isMuted = !this.isMuted;
        if (!this.isMuted) {
          document.querySelector("#vid").muted = false;
        } else {
          document.querySelector("#vid").muted = true;
        }
        console.log(this.isMuted);
      },
      init() {
        /*this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        const tween = TweenMax.to(keyVisual.querySelector(".video-item"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        /!*const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });*!/
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween]);
        this.$scrollmagic.addScene(this.scene);*/
      },
      async magazineList() {
        try {
          const {
            data: {data: response}
          } = await apiMagazineList({
            page: 0,
            size: 3,
            Keyword: ""
          });
          this.magazineListData = response.content;
          this.totalLength = parseInt(response.totalElements);
          console.log(response.content);
        } catch (error) {
          console.error(error);
        }
      },
      async workList() {
        try {
          const {
            data: {data: response}
          } = await apiWorksList({
            page: 0,
            size: 7,
            Keyword: ""
          });
          this.WorksListData = this.WorksListData.concat(response.content);
          //console.log(response.content);
        } catch (error) {
          console.error(error);
        }
      }
    },
    created() {
      this.magazineList();
      this.workList();
    }
  };
</script>
<style lang="scss" scoped>
  .key-visual {
    //position: relative;
    //min-height: 779px;
    //height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    .contents {
      z-index: 2;
      position: relative;
      display: flex;
      //justify-content: center;
      flex-direction: column;
      height: 100%;
    }
    .txt {
      margin-top: 62.5%;
      @include montserrat;
      font-size: 36px;
      line-height: 45px;
      font-weight: bold;
      color: $red;
    }
    .key-button {
      flex: 0 0 auto;
      display: block;
      border: none;
      border-radius: 50%;
      background: $red;
      font-size: 13px;
      @include montserrat();
      color: #fff;
      height: 84px;
      width: 84px;
      text-align: center;
      line-height: 84px;
      margin-top: 57px;
    }
    /*.bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }*/
    .btn-area {
      display: flex;
      justify-content: space-between;
      z-index: 2;
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: calc(100% - 40px);

      button {
        position: relative;
        .lottie-container {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 16px;
          height: 12px;
          transform: translate(-50%, -50%);
        }
        &.muted {
          background: rgba(0, 0, 0, 0.4) url("../assets/images/ic_unmute.svg") no-repeat center;
          background-size: 20px;
        }
      }

      .right {
        display: flex;
      }
      button,
      a {
        border-radius: 20px;
        display: inline-block;
        padding: 10px 20px;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        border: 1px solid #ddd;
        background: rgba(0, 0, 0, 0.4);
      }
      a + a {
        margin-left: 10px;
      }
      .social {
        width: 40px;
        height: 40px;
        padding: 0;
        background: rgba(0, 0, 0, 0.4) url("../assets/images/ic_sns_facebook.svg") no-repeat center;
      }
    }
    .video-item {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      min-width: $globalWidth;
      padding-bottom: 113%;
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 55px;
    .title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      color: #eb1624;
      span {
        color: #a0a0a0;
      }
    }
    .sub-title {
      display: block;
      @include montserrat();
    }
    .more {
      padding-bottom: 2.5px;
      @include montserrat();
      font-size: 13px;
      font-weight: 300;
      color: #000;
      border-bottom: 1px solid #000;
    }
  }
  .swiper-area {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .swiper-item {
      flex: 0 0 190px;
      width: 190px;
      & + .swiper-item {
        position: relative;
        &:before {
          position: absolute;
          top: 50%;
          left: -12px;
          transform: translateY(calc(-50% - 25px));
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #ee2c3c;
        }
      }
      .about-item {
        display: block;
        color: #000;
        .thumb {
          display: block;
          width: 130px;
          height: 130px;
          margin: 0 auto;
          border-radius: 100%;
          overflow: hidden;
          text-align: center;
          transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955),
            -webkit-transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          img {
            vertical-align: top;
            object-fit: cover;
          }
        }
        .title {
          display: block;
          margin-top: 40px;
          @include montserrat();
          text-align: center;
          font-size: 13px;
          line-height: 17px;
          font-weight: bold;
        }
      }
    }
  }
  .work-list {
    margin: 40px auto 0;
    .work-item {
      & + .work-item {
        margin-top: 30px;
      }
      a {
        position: relative;
        display: block;
      }
      .thumb {
        display: block;
        overflow: hidden;
        img {
          width: 100%;
          vertical-align: top;
          object-fit: cover;
        }
      }
      .title {
        position: absolute;
        top: 20px;
        left: 20px;
        font-weight: bold;
      }
    }
  }
  .magazine-list {
    margin-top: 41px;
    padding-bottom: 74.5px;
    li {
      box-sizing: border-box;
      & + li {
        margin-top: 60px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      position: relative;
      display: block;
      height: auto;
      .num {
        display: block;
        @include montserrat();
        font-size: 24px;
        line-height: 18px;
        font-weight: bold;
        color: #000;
      }
      .title {
        position: relative;
        display: block;
        box-sizing: border-box;
        margin-top: 20px;
        padding-top: 27px;
        font-size: 18px !important;
        line-height: 29px !important;
        text-align: left !important;
        font-weight: bold;
        color: #000;
        word-break: keep-all;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #ee2c3c;
        }
      }
      .date {
        display: block;
        margin-top: 35px;
        @include montserrat();
        font-size: 11px;
        color: #b7b7b7;
      }
      .desc {
        display: block;
        margin-top: 15px;
        font-size: 14px;
        line-height: 23px;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: keep-all;
      }
    }
  }
</style>
